import React, { useRef } from 'react';
import Container from '../Container';
import TripleTitle from '../../common/TripleTitle';
import presets from '../../../styles/presets';
import { SYSTEM_FONTS } from '../../../styles/typography';

import { ReactComponent as Quotes } from '../../../assets/images/reviews/img-quotation.svg';
import { ReactComponent as Stars } from '../../../assets/images/reviews/img-5star_rating.svg';
import Slider, { Settings } from 'react-slick';
import SliderArrow from '../../common/slider/SliderArrow';
import theme from '../../../styles/theme';
import { css } from '@emotion/core';
import styled from '@emotion/styled';

const settings: Settings = {
  dots: false,
  centerMode: true,
  infinite: true,
  slidesToShow: 1,
  slidesToScroll: 1,
  arrows: true,
  nextArrow: <SliderArrow position="right" />,
  prevArrow: <SliderArrow position="left" />,
  focusOnSelect: false,
  swipeToSlide: true,
  draggable: false,
  responsive: [
    {
      breakpoint: theme.breakpoints.md,
      settings: {
        centerMode: false,
        arrows: false,
        nextArrow: null,
        prevArrow: null,
      },
    },
    {
      breakpoint: theme.breakpoints.lg,
      settings: {
        centerMode: false,
        arrows: false,
      },
    },
  ],
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const ReviewCard = ({ text, author, date }: any) => (
  <div
    css={{
      backgroundColor: 'rgba(151, 117, 193, 0.11)',
      borderRadius: '16px',
      padding: '32px 32px',
      position: 'relative',
      textAlign: 'left',
      width: '100%',
      maxWidth: '500px',
      margin: '3rem auto 5px',

      [presets.lg]: {
        padding: '50px 50px 32px',
        margin: '4rem auto 5px',
      },

      [presets.xl]: {
        padding: '50px 80px 32px',
        margin: '4rem auto 5px',
      },
    }}
  >
    <Quotes
      width="85"
      css={{
        position: 'absolute',
        marginTop: '-60px',
        marginLeft: '10px',
        width: '65px',

        [presets.lg]: {
          marginTop: '-90px',
          marginLeft: '-30px',
          width: '185px',
        },
      }}
    />

    <Stars
      css={{
        marginLeft: '-3px',
        marginBottom: '8px',
      }}
    />

    <p
      css={{
        color: '#000',
        fontSize: '0.875rem',
        fontFamily: ['Open Sans'].concat(SYSTEM_FONTS).join(', '),
        [presets.lg]: { fontSize: '1rem' },
      }}
      dangerouslySetInnerHTML={{ __html: text }}
    />

    <p css={{ textAlign: 'right', marginBottom: 0 }}>
      <span
        css={{
          display: 'block',
          fontFamily: ['Blacker Pro Display'].concat(SYSTEM_FONTS).join(', '),
          fontSize: '20px',
          fontWeight: 600,
          color: '#160B2C',
          lineHeight: '18px',

          [presets.lg]: {
            fontSize: '24px',
            lineHeight: '22px',
          },
        }}
      >
        {author}
      </span>
      <small
        css={{
          display: 'block',
          color: '#000',
          opacity: 0.4,
          fontSize: '12px',

          [presets.lg]: {
            fontSize: '16px',
          },
        }}
      >
        {date}
      </small>
    </p>
  </div>
);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default function Reviews({ prefix, title, items, text }: any) {
  const ref = useRef<Slider>(null);

  const MobileArrows = styled.div(css`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: left;
    align-self: flex-start;
    margin-left: 20px;

    ${presets.lg} {
      display: none;
    }
  `);

  const settingsStyle = css`
    max-width: 1000px;
    margin: 0 auto;

    .slick-prev,
    .slick-next {
      top: 40%;
      z-index: 1;
      padding: 0 30px;
    }

    .slick-next:before,
    .slick-prev:before {
      content: '';
    }
  `;

  return (
    <Container css={{ marginTop: '60px', marginBottom: '0px' }}>
      <TripleTitle
        labelText={prefix}
        headingText={title}
        paragraphText={text}
      />

      <Slider ref={ref} {...settings} css={settingsStyle}>
        {items &&
          items.map(
            (item: {
              id: string | number;
              text: never;
              author: React.ReactNode;
              date: React.ReactNode;
            }) => (
              <div
                key={item.id}
                css={{
                  padding: '0 20px',
                }}
              >
                <ReviewCard
                  text={item.text}
                  author={item.author}
                  date={item.date}
                />
              </div>
            )
          )}
      </Slider>
      <MobileArrows
        css={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <SliderArrow position="left" onClick={() => ref.current.slickPrev()} />
        <SliderArrow position="right" onClick={() => ref.current.slickNext()} />
      </MobileArrows>
    </Container>
  );
}
